import React from "react";
import styled from "styled-components";
import { format } from "date-fns";

import { Link } from "gatsby";

const Writing = styled.ul``;
const Piece = styled.li`
  margin-bottom: 4rem;
`;

Piece.TitleOffsiteLink = styled.a`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
`;

Piece.Title = styled(Link)`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
`;

Piece.TitleNoLink = styled.p`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
`;

Piece.Information = styled.p``;
Piece.Date = styled.p`
  margin-bottom: 1rem;
  opacity: 0.7;
`;
Piece.Abstract = styled.p`
  position: relative;
  margin-bottom: 1rem;
  max-width: 600px;
`;

const WritingContainer = ({ writing }) => {
  return (
    <Writing>
      <Piece>
        <Piece.Abstract>
          What appears below is a sampling of writing produced during the
          graduate program at Bard Center for Curatorial Studies. Full essays
          available{" "}
          <a href=" mailto:brooke.is.nicholas@gmail.com?subject=Essay%20Request&body=I'd%20like%20to%20request%20the%20full%20copy%20of%20the%20essay%20%5B%20add%20title%20here%20%5D. ">
            upon request
          </a>
          .
        </Piece.Abstract>
      </Piece>
      <Piece>
        <Piece.TitleOffsiteLink
          href={`https://itlaunches.today/projects/brooke-nicholas`}
          target="_blank"
          rel="noreferrer"
        >
          Jingles and Exploiting the Earworm
        </Piece.TitleOffsiteLink>
        <Piece.Date>
          Published 2020 on{" "}
          <a
            href="https://itlaunches.today"
            target="_blank"
            rel="noopener noreferrer"
          >
            ItLaunches.Today
          </a>
        </Piece.Date>
        <Piece.Abstract>
          1000 words about the history of the jingle, a form of musical
          advertising that prays on human ability to recreate catchy sounds
          internally. The essay was produced alongside a commissioned work,{" "}
          <i>The Jingle</i>, created by musician and composer Evan Backer. With{" "}
          <i>The Jingle</i>, Backer recreates the earworm effect by rapidly
          introducing material from different eras of jingle history through
          distracting compound sounds and quick cuts between sections.
        </Piece.Abstract>
      </Piece>
      {writing.map((piece) => {
        const { node } = piece;
        const { childMarkdownRemark } = node;
        const { frontmatter } = childMarkdownRemark;
        const { title, abstract, publicationDate } = frontmatter;
        return (
          <Piece key={title}>
            <Piece.TitleNoLink
            // to={`/${title.replace(/[^0-9a-zA-Z]/g, "-").toLowerCase()}`}
            >
              {title}
            </Piece.TitleNoLink>
            <Piece.Date>
              Published {format(new Date(publicationDate), "yyyy")}{" "}
            </Piece.Date>
            <Piece.Abstract>{abstract}</Piece.Abstract>
          </Piece>
        );
      })}
    </Writing>
  );
};

export default WritingContainer;
