import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Seo from "../../components/seo";

import Writing from "../../containers/writing";

const WritingPage = ({ data }) => {
  const { allFile } = data;
  const writing = allFile.edges;
  return (
    <Fragment>
      <Seo title="Writing" />
      <Writing writing={writing} />
    </Fragment>
  );
};

export const writingQuery = graphql`
  query WritingQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "writing" } }
      sort: {
        fields: childMarkdownRemark___frontmatter___publicationDate
        order: DESC
      }
    ) {
      edges {
        node {
          id
          sourceInstanceName
          childMarkdownRemark {
            frontmatter {
              title
              publicationDate
              abstract
            }
          }
        }
      }
    }
  }
`;

export default WritingPage;
